<template>
  <div class="create-content-main py-3 px-3 card-content">
    <div class="create-slag-form card">
      <div class="d-flex mt-3">
        <h2 v-if="isEdit" class="mr-0 col-6 cpx-4">ニュース編集</h2>
        <h2 v-else class="mr-0 col-6 cpx-4">ニュース作成</h2>
        <div class="cpx-4 col-6 text-right">
          <b-button
            v-b-modal.modal-preview-content
            class="btn-other btn-preview-fixed mr-0 my-auto"
            style="height: 42px"
            variant="primary"
          >
            プレビュー
          </b-button>
        </div>
      </div>
      <form
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4">NO</label>
          <div class="col-12 cp-4">
            <input class="form-input m-0" type="text" v-model="no" disabled />
          </div>
        </div>
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4"
            >ニュースタイトル</label
          >
          <div class="col-12 cp-4">
            <input
              class="form-input m-0"
              v-bind:class="{ 'error-input': infoNew.errorTitleRequired }"
              type="text"
              v-model="infoNew.title"
            />
            <div class="message-error" v-if="infoNew.errorTitleRequired">
              {{ feedback.REQUIRED }}
            </div>
          </div>
        </div>
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4">ニュース本文</label>
          <div class="col-12 cp-4">
            <vue-editor
              v-model="infoNew.content"
              class="w-100"
              style="background-color: white; color: black"
              v-bind:class="{ 'error-input': infoNew.errorContentRequired }"
            ></vue-editor>
            <div class="message-error" v-if="infoNew.errorContentRequired">
              {{ feedback.REQUIRED }}
            </div>
          </div>
        </div>
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4">投稿日時</label>
          <div class="col-12 cp-4">
            <div class="fd-admin-listproperty-card-filter filter-date w-25">
              <date-picker
                name="date"
                v-model="infoNew.postTime"
                :config="optionsDatePostTime"
                autocomplete="off"
                style="margin-bottom: 1rem"
                placeholder="日付を選択"
                v-bind:class="{ 'error-input': infoNew.errorPostTimeRequired }"
              ></date-picker>
              <div class="message-error" v-if="infoNew.errorPostTimeRequired">
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
        </div>
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4">表示設定</label>
          <div class="col-12 cp-4">
            <label
              class="custom-ListLabel"
              v-bind:class="{ checked: index === infoNew.typeDisplay }"
              v-on:click="clickTypeDisplay(index, $event)"
              v-for="(item, index) in listLabelDisplay"
              :key="index"
            >
              {{ item.text }}
            </label>
          </div>
        </div>
        <div class="row-input" v-if="infoNew.typeDisplay === 1">
          <label class="col-sm-12 font-weight-bold cpx-4"
            >オーナー設定(一部に表示の場合)</label
          >
          <div class="col-12 cp-4">
            <div class="custom-select-2 w-100 custome-multi-slag">
              <multiselect
                v-model="infoNew.ownerId"
                tag-placeholder="オーナーを選んでください"
                placeholder="オーナーを選んでください"
                label="text"
                track-by="text"
                :options="optionOwner"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
                v-bind:class="{ 'error-input': infoNew.errorOwnerRequired }"
              ></multiselect>
              <div class="message-error" v-if="infoNew.errorOwnerRequired">
                {{ feedback.REQUIRED }}
              </div>
            </div>
          </div>
        </div>
        <div class="row-input">
          <label class="col-sm-12 font-weight-bold cpx-4">公開ステータス</label>
          <div class="col-12 cp-4">
            <div class="w-100">
              <b-form-select
                v-model="infoNew.isPublic"
                :options="optionStatus"
                class="col-sm-2 mr-3"
              ></b-form-select>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mb-3">
        <b-button
          v-if="isEdit"
          v-b-modal.delete-modal-new
          variant="danger"
          class="mr-4"
        >
          ページを削除
        </b-button>
        <b-button
          v-on:click.prevent="saveNew()"
          :disabled="loadingSave"
          variant="success"
          class="mr-4"
        >
          <b-spinner v-if="loadingSave" small></b-spinner>
          編集内容を反映
        </b-button>
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnListNew()"
          >戻る</b-button
        >
      </div>
      <!-- Modal preview content -->
      <b-modal
        id="modal-preview-content"
        size="xl"
        :title="infoNew.title"
        hide-footer
        scrollable
      >
        <CRow>
          <CCol sm="12">
            <div class="d-flex flex-column" v-html="infoNew.content"></div>
          </CCol>
          <CCol sm="12">
            <div class="d-flex justify-content-center">
              <b-button
                variant="secondary"
                @click="$bvModal.hide('modal-preview-content')"
                class="mx-2"
                >閉じる</b-button
              >
            </div>
          </CCol>
        </CRow>
      </b-modal>
      <!-- Modal preview content -->
      <!-- Modal delete new -->
      <b-modal id="delete-modal-new" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除しますと復元できませんのでご注意ください</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="deleteNew()"
            :disabled="loadingDelete"
            ><b-spinner v-if="loadingDelete" small></b-spinner>削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-new')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
      <!-- Modal delete new -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { FeedBack } from "@/utils/feedback.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
import { Urls } from "../../utils/urls";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "CreateCategory",
  components: {
    VueEditor,
  },
  data() {
    return {
      feedback: FeedBack,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      no: "",
      infoNew: {
        title: "",
        errorTitleRequired: false,
        content: "",
        errorContentRequired: false,
        postTime: "",
        errorPostTimeRequired: false,
        ownerId: [],
        errorOwnerRequired: false,
        typeDisplay: 0,
        isPublic: 0,
      },
      optionsDatePostTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      listLabelDisplay: [
        { value: 0, text: "全員に表示" },
        { value: 1, text: "一部に表示" },
      ],
      optionStatus: [
        { value: 0, text: "非公開" },
        { value: 1, text: "公開中" },
      ],
      optionOwner: [],
      loadingDelete: false,
      loadingSave: false,
      loadingCategory: false,
      loadingOwner: false,
      page: 2,
      isEdit: false,
      limit: 50,
    };
  },
  created() {
    const { params } = this.$route;
    const { id } = params;
    if (id) {
      this.isEdit = true;
      this.no = this.$route.query.NO;
      this.getNewAdminById(id);
    } else {
      this.no = this.$route.query.total;
    }
    const formData = {
      page: 1,
      limit: this.limit,
    };
    this.getListOwner(formData);
  },
  computed: {
    ...mapGetters([
      "listSlag",
      "success",
      "message",
      "error",
      "detailNewAdmin",
      "listOwner",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.loadingDelete = false;
        this.loadingSave = false;
        this.loadingCategory = false;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.push({
          name: "list new cdea",
        });
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.loadingDelete = false;
        this.loadingSave = false;
        this.loadingCategory = false;
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    listUser() {
      this.optionUser = this.listUser.data.map((item) => ({
        value: item.id,
        text: item.email,
      }));
    },
    detailNewAdmin() {
      const {
        news_title,
        news_content,
        post_date,
        type_display,
        is_public,
        shops,
      } = this.detailNewAdmin;
      this.infoNew.title = news_title;
      this.infoNew.content = news_content;
      this.infoNew.postTime = post_date;
      this.infoNew.typeDisplay = type_display;
      this.infoNew.isPublic = is_public;
      this.infoNew.ownerId = shops.map((item) => ({
        value: item.shop_id,
        text: item.email,
      }));
    },
    listOwner() {
      this.optionOwner = this.listOwner.data.map((item) => ({
        value: item.shop_id,
        text: item.email,
      }));
    },
    "infoNew.title"() {
      if (this.infoNew.title) {
        this.infoNew.errorTitleRequired = false;
      }
    },
    "infoNew.content"() {
      if (this.infoNew.content) {
        this.infoNew.errorContentRequired = false;
      }
    },
    "infoNew.postTime"() {
      if (this.infoNew.postTime) {
        this.infoNew.errorPostTimeRequired = false;
      }
    },
  },
  methods: {
    ...mapActions({ createNewAdmin: "createNewAdmin" }),
    ...mapActions({ updateNewAdmin: "updateNewAdmin" }),
    ...mapActions({ getNewAdminById: "getNewAdminById" }),
    ...mapActions({ getListContent: "getListContent" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListCategoryNew: "getListCategoryNew" }),
    ...mapActions({ getListOwner: "getListOwner" }),
    clickTypeDisplay(index) {
      if (this.infoNew.typeDisplay !== index) {
        this.infoNew.typeDisplay = index;
      }
    },
    deleteNew() {
      this.loadingDelete = true;
      const { id } = this.$route.params;
      Api.cdeaRequestServer
        .delete(`/${Urls.ADMIN}/${Urls.NEWS_ADMIN}/${id}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.loadingDelete = false;
            this.$bvModal.hide("delete-modal-new");

            this.$toasted.success(data.message);
            this.$router.push({
              name: "list new cdea",
            });
          } else {
            this.loadingDelete = false;
            this.$toasted.error(this.data.message);
          }
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toasted.error(error.message);
        });
    },
    returnListNew() {
      this.$router.push({
        name: "list new cdea",
        params: { shopId: this.shop_id },
      });
    },
    saveNew() {
      this.loadingSave = true;
      let error = false;
      if (this.infoNew.title.trim() === "") {
        this.infoNew.errorTitleRequired = true;
        error = true;
      }
      if (this.infoNew.content === "") {
        this.infoNew.errorContentRequired = true;
        error = true;
      }
      if (!this.infoNew.postTime) {
        this.infoNew.errorPostTimeRequired = true;
        error = true;
      }
      if (error) {
        this.loadingSave = false;

        this.$toasted.error("入力内容に誤りがあります。");
      } else {
        const request = {
          id: this.$route.params.id ? this.$route.params.id : "",
          news_title: this.infoNew.title,
          news_content: this.infoNew.content,
          post_date: this.infoNew.postTime + ":00",
          type_display: this.infoNew.typeDisplay,
          is_public: this.infoNew.isPublic,
          shops_id: this.infoNew.typeDisplay === 1 ? this.infoNew.ownerId : [],
        };
        if (request.id) {
          this.updateNewAdmin(request);
        } else {
          this.createNewAdmin(request);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.form-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  font-size: 0.875rem;
  font-weight: 550;
  line-height: 1.5;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: #d8dbe0;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}
</style>
